<template>
  <div class="copyrig-service-info">
    <div class="fast-application">
      <div class="title">
        <div>免费查询能否登记</div>
        <p>美术作品 文字作品 摄影作品 口述作品 图形作品 其他作品</p>
      </div>
      <div class="intelligent">
        <div class="intelligent-title">{{ patentList.patentTitle }}</div>
        <div class="application-Info">
          <a-select
            label-in-value
            :default-value="{ key: '美术作品版权登记' }"
            style="width: 220px"
            @change="handleChange"
          >
            <a-select-option
              v-for="(item, index) in patentList.list"
              :key="index + 'select'"
            >
              {{ item }}
            </a-select-option>
          </a-select>
          <div style="margin-left: 20px">
            <a-input
              v-model="patentList.title"
              style="width: 220px"
              placeholder="意向作品名称"
            />
          </div>
          <div style="margin-left: 20px">
            <a-input
              v-model="patentList.tel"
              style="width: 220px"
              placeholder="手机号码"
            />
          </div>
          <div class="obtain-code" style="margin-left: 20px">
            <a-input
              v-model="patentList.code"
              class="inp"
              style="width: 110px"
              placeholder="验证码"
            />
            <span class="text"
              ><span
                style="
                  border-radius: 10px;
                  cursor: pointer;
                  width: 100px;
                  text-align: center;
                "
                class="msg__btn"
                @click="getSmsCode"
                :disabled="state.smsSendBtn"
                >{{ state.smsSendBtn ? state.time : "获取验证码" }}</span
              >
            </span>
          </div>
          <a-button @click="setNeeds()" style="margin-left: 20px" type="danger">
            提交需求
          </a-button>
        </div>
      </div>
    </div>
    <div class="fast-info">
      <div class="header">热门版权服务</div>
      <Service
        :copyright="$store.state.home.copyrightList.serviceList"
        :type="'copyrightList'"
      />
    </div>
    <div class="patent-value-info">
      <div class="value-info">
        <div class="value-title">版权其他服务</div>
        <div class="value-tab">
          <div
            class="value-item"
            v-for="(item, index) in $store.state.home.copyrightList.lawList"
            :key="index + 'value'"
          >
            <div class="left">
              <div class="header">
                <img :src="item.imgurl" alt="" />
                <div class="title">{{ item.title }}</div>
                <div class="text">
                  {{ item.text }}
                </div>
              </div>
            </div>
            <div class="footer">
              <div class="price">
                <span class="text">申请价格:</span>
                <span class="sign">￥</span>
                <span class="money">{{ item.money }}</span>
              </div>
              <div class="btn" @click="showModel(item)">立即咨询</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="application-benefits">
      <div class="benefits-title">
        <div class="title">为什么要申请软件著作权</div>
        <div class="text">没有被保护的作品随时面临被抄袭、侵权的风险</div>
      </div>
      <div class="benefits-tab">
        <div
          class="benefits-item"
          v-for="(item, index) in benefitsList"
          :key="index + 'benefits'"
        >
          <div class="logo"><img :src="item.imgUrl" alt="" /></div>
          <div class="title">{{ item.title }}</div>
          <div class="text">
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
    <div class="process-info">
      <div class="flow-chart">
        <div class="title">版权登记流程</div>
        <div class="flow-chart-tab">
          <div
            class="flow-chart-item"
            v-for="(item, index) in flowList"
            :key="index + 'flow'"
          >
            <div class="logo">
              <img :src="item.imgUrl" alt="" />
            </div>
            <div class="text">{{ item.text }}</div>
            <div class="text1">{{ item.text1 }}</div>
          </div>
        </div>
      </div>
      {{ $store.state.user.guwen.copyrightList }}
    </div>
    <Model ref="model" />
    <Floating :consultant="$store.state.user.guwen.copyrightList" />
    <Footer />
  </div>
</template>

<script>
import Footer from "../../comon/Footer.vue";
import cookie from "../../utils/cookie";
import { needs, smsCode } from "../../api/index";
import Service from "../comon/Service.vue";
import Floating from "../../comon/Floating.vue";
import Model from "../../comon/model.vue";
export default {
  name: "CopyrightService",

  components: { Footer, Model, Service, Floating },

  directives: {},

  data() {
    return {
      form: this.$form.createForm(this, { name: "horizontal_login" }),
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        selectindex: 0,
        smsSendBtn: false,
        read: false,
      },
      patentList: {
        patentTitle: "版权登记",
        title: "",
        list: ["美术作品版权登记", "文字作品版权登记", "计算机软件著作权登记"],
        category: 3,
        tel: "",
        type: "美术作品版权登记",
        code: "",
      },
      benefitsList: [
        {
          imgUrl: require("../../assets/patentservice/benefits/benefits-icon0.png"),
          title: "推广宣传",
          text: "提升个人或企业实力形象",
        },
        {
          imgUrl: require("../../assets/patentservice/benefits/benefits-icon1.png"),
          title: "防止抄袭",
          text: "保护自身创作不被他人模仿侵权",
        },
        {
          imgUrl: require("../../assets/patentservice/benefits/benefits-icon2.png"),
          title: "解决纠纷",
          text: "遇到纠纷可作为法律证据",
        },
        {
          imgUrl: require("../../assets/patentservice/benefits/benefits-icon2.png"),
          title: "证明原创",
          text: "有力证明原创归属",
        },
      ],
      flowList: [
        {
          text1: "专家审核、校对，确定申报的材料",
          text: "材料准备",
          imgUrl: require("../../assets/banquan/bq-logo1.png"),
        },
        {
          text1: "递交审核资料至国家版权局",
          imgUrl: require("../../assets/banquan/bq-logo2.png"),
          text: "递交版权局",
        },
        {
          text1: "获取受理通知书，跟进受理过程",
          text: "跟进过程",
          imgUrl: require("../../assets/banquan/bq-logo3.png"),
        },
        {
          text: "获得证书",
          text1: "告知办理成功核发版权许可证",
          imgUrl: require("../../assets/banquan/bq-logo4.png"),
        },
      ],
    };
  },

  mounted() {},

  methods: {
    async getSmsCode(e) {
      if (this.state.smsSendBtn)
        return this.$message.error("请勿重复提交验证码");
      if (this.patentList.tel === "")
        return this.$message.error("请填写手机号");
      e.preventDefault();
      const {
        form: { validateFields },
        state,
      } = this;
      const params = {
        phone: this.patentList.tel,
        useTo: 6,
      };
      const res = await smsCode(params);
      validateFields(["subForm[phone]"], { force: true }, (err) => {
        if (!err) {
          state.smsSendBtn = true;
          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              state.time = 60;
              state.smsSendBtn = false;
              window.clearInterval(interval);
            }
          }, 1000);
          const hide = this.$message.loading("验证码发送中..", 0);
          // tianli
          if (res.code === 200) {
            setTimeout(() => {
              this.$message.success({
                content: "发送成功，请在手机中查看!",
                duration: 2,
              });
            }, 800);
          } else {
            setTimeout(() => {
              this.$message.success({
                content: "发送失败，请重新发送!",
                duration: 2,
              });
            }, 0);
          }
          setTimeout(hide, 2500);
        }
      });
    },
    async setNeeds() {
      if (this.patentList.title === "")
        // return this.$message.error("请填写专利名称");
        return this.$message.error("请填写意向作品名称");
      if (this.patentList.tel === "")
        return this.$message.error("请填写手机号");
      if (this.patentList.code === "")
        return this.$message.error("请填写验证码");
      const params = {
        ownerId: cookie.getCookie("user")
          ? JSON.parse(cookie.getCookie("user")).id
          : null,
        code: this.patentList.code,
        title: this.patentList.title,
        category: this.patentList.category,
        tel: this.patentList.tel,
        type: this.patentList.type,
      };
      const res = await needs(params);
      if (res.code === 200) {
        this.$message.success("提交需求成功！");
        this.patentList.contact = "";
        this.patentList.tel = "";
        this.patentList.code = "";
        this.patentList.title = "";
      } else {
        this.$message.error(res.msg);
      }
    },
    showModel(item) {
      this.$refs.model.visible = true;
      this.$store.state.home.modalParms = item;
    },
    handleChange(value) {
      console.log(value); // { key: "lucy", label: "Lucy (101)" }
    },
  },
};
</script>

<style lang="less">
@import "../../less/copyrightservice.less";
</style>
